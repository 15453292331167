<script>
// import Chunk from 'lodash/chunk'
import UniqBy from 'lodash/uniqBy'
import FindIndex from 'lodash/findIndex'
import PickBy from 'lodash/pickBy'
// import Identity from 'lodash/identity'
import Bus from '@utils/emitter'

export default {
  name: 'FlotoPaginatedList',
  props: {
    allowKeyboardNavigation: { type: Boolean, default: false },
    // eslint-disable-next-line
    immediate: { type: Boolean, default: true },
    fetchFn: { type: Function, required: true },
    defaultSort: { type: String, default: undefined },
    pageSize: { type: Number, default: 10 },
    page: { type: Number, default: 1 },
    // eslint-disable-next-line
    paging: { type: Boolean, default: true },
    maxAllowedSelection: { type: Number, default: 0 },
    defaultSelectedItemIds: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      items: [],
      total: 0,
      currentCursorPosition: this.allowKeyboardNavigation ? 0 : undefined,
      activePageSize: this.pageSize,
      sort: this.defaultSort,
      currentPage: this.page || 1,
      loading: true,
      loadingMore: false,
      loadingMoreItems: false,
      error: null,
      selectedItemIds: [],
      selectedItemFullDetails: [],
    }
  },
  computed: {
    offset() {
      return this.getOffset(this.currentPage)
    },
    hasMoreItem() {
      return this.currentPage < Math.ceil(this.total / this.activePageSize)
    },
  },
  watch: {
    defaultSelectedItemIds: {
      immediate: true,
      handler(newValue) {
        this.$nextTick(() => {
          this.selectedItemIds = newValue
        })
      },
    },
  },
  mounted() {
    if (this.immediate) {
      this.navigateToPage(1)
    }
    if (this.allowKeyboardNavigation) {
      const upHandler = (e) => {
        if (e.target.nodeName === 'INPUT') {
          return
        }
        if (this.items.length === 0) {
          return
        }
        requestAnimationFrame(() => {
          this.currentCursorPosition =
            (this.currentCursorPosition + this.items.length - 1) %
            this.items.length
        })
      }
      const downHandler = (e) => {
        if (e.target.nodeName === 'INPUT') {
          return
        }
        if (this.items.length === 0) {
          return
        }
        requestAnimationFrame(() => {
          this.currentCursorPosition =
            (this.currentCursorPosition + 1) % this.items.length
        })
      }
      const enterHandler = (e) => {
        if (e.target.nodeName === 'INPUT') {
          return
        }
        requestAnimationFrame(() => {
          this.$emit('select-item', this.items[this.currentCursorPosition])
        })
      }
      const spaceHandler = (e) => {
        if (e.target.nodeName === 'INPUT') {
          return
        }
        this.toggleSelectItem(this.items[this.currentCursorPosition])
      }
      const nextPageHandler = (e) => {
        if (e.target.nodeName === 'INPUT') {
          return
        }
        if (this.hasMoreItem) {
          this.navigateToPage(this.currentPage + 1)
        }
      }
      const previousPageHandler = (e) => {
        if (e.target.nodeName === 'INPUT') {
          return
        }
        if (this.currentPage !== 1) {
          this.navigateToPage(this.currentPage - 1)
        }
      }
      Bus.$on('move_up', upHandler)
      Bus.$on('move_down', downHandler)
      Bus.$on('enter', enterHandler)
      Bus.$on('space', spaceHandler)
      Bus.$on('next-page', nextPageHandler)
      Bus.$on('previous-page', previousPageHandler)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('move_up', upHandler)
        Bus.$off('move_down', downHandler)
        Bus.$off('enter', enterHandler)
        Bus.$off('space', spaceHandler)
        Bus.$off('next-page', nextPageHandler)
        Bus.$off('previous-page', previousPageHandler)
      })
    }
  },
  beforeDestroy() {
    this.items = []
    this.selectedItemIds = []
  },
  methods: {
    applySort(column) {
      if ((this.sort || '').replace('-', '') === column) {
        if (/^-/.test(this.sort)) {
          this.sort = column
        } else {
          this.sort = `-${column}`
        }
      } else {
        this.sort = column
      }
      this.navigateToPage(1, false)
    },
    toggleSelectAll(selectValue, allClear = false) {
      if (selectValue) {
        this.selectedItemFullDetails = [
          ...(this.selectedItemFullDetails || []),
          ...(this.items || []),
        ]
        this.selectedItemIds = [
          ...(this.selectedItemIds || []),
          ...this.items.map(({ id }) => id),
        ]
      } else {
        this.selectedItemIds = allClear
          ? []
          : (this.selectedItemIds || []).filter(
              (s) => this.items.map(({ id }) => id).indexOf(s) === -1
            )
        this.selectedItemFullDetails = allClear
          ? []
          : (this.selectedItemFullDetails || []).filter(
              (s) => this.items.map(({ id }) => id).indexOf(s.id) === -1
            )
      }
      this.$nextTick(() => {
        this.$emit(
          'selection-change',
          this.selectedItemIds.map((id) =>
            this.selectedItemFullDetails.find((i) => i.id === id)
          ),
          this.selectedItemFullDetails
        )
      })
    },
    toggleSelectItem(item) {
      const itemId = item.id
      const index = this.selectedItemIds.indexOf(itemId)
      const fullItmeIndex = this.selectedItemFullDetails
        .map((s) => s.id)
        .indexOf(itemId)
      if (index !== -1) {
        this.selectedItemIds = this.selectedItemIds.filter((x) => x !== itemId)
      } else {
        if (
          this.maxAllowedSelection > 0 &&
          this.selectedItemIds.length >= this.maxAllowedSelection
        ) {
          this.selectedItemIds = [
            ...this.selectedItemIds.slice(
              this.selectedItemIds.length - (this.maxAllowedSelection - 1)
            ),
            itemId,
          ]
        } else {
          this.selectedItemIds = [...this.selectedItemIds, itemId]
        }
      }
      if (fullItmeIndex !== -1) {
        this.selectedItemFullDetails = this.selectedItemFullDetails.filter(
          (x) => x.id !== itemId
        )
      } else {
        if (
          this.maxAllowedSelection > 0 &&
          this.selectedItemFullDetails.length >= this.maxAllowedSelection
        ) {
          this.selectedItemFullDetails = [
            ...this.selectedItemFullDetails.slice(
              this.selectedItemFullDetails.length -
                (this.maxAllowedSelection - 1)
            ),
            item,
          ]
        } else {
          this.selectedItemFullDetails = [...this.selectedItemFullDetails, item]
        }
      }
      this.$nextTick(() => {
        this.$emit(
          'selection-change',
          this.selectedItemIds.map((id) =>
            this.selectedItemFullDetails.find((i) => i.id === id)
          ),
          this.selectedItemFullDetails
        )
      })
    },
    replace(item) {
      const index = FindIndex(this.items, { id: item.id })
      if (index !== -1) {
        this.items = [
          ...this.items.slice(0, index),
          item,
          ...this.items.slice(index + 1),
        ]
      }
    },
    prepend(item) {
      this.items = [item, ...this.items]
      this.total -= 1
    },
    append(item) {
      if (!this.hasMoreItem) {
        if (this.total !== this.activePageSize) {
          this.items = [...this.items, item]
        }
        this.total += 1
      }
    },
    remove(item) {
      this.items = this.items.filter(({ id }) => id !== item.id)
      this.total -= 1
      if (
        this.total &&
        this.currentPage !== 1 &&
        (this.items || []).length === 0
      ) {
        return this.$nextTick(() => {
          this.navigateToPage(this.currentPage - 1)
        })
      }
    },
    refresh() {
      // @TODO apply some logic so all items gets updated if loadmore is enabled
      const refreshPage = this.page
      this.toggleSelectAll(false, true)
      return this.navigateToPage(refreshPage, false)
    },
    refreshCurrentPage() {
      // @TODO apply some logic so all items gets updated if loadmore is enabled
      this.toggleSelectAll(false, false)
      return this.navigateToPage(this.currentPage, false)
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
      this.loadingMore = false
    },
    loadMoreItems() {
      if (this.hasMoreItem) {
        this.loadingMore = true
        this.navigateToPage(this.currentPage + 1, true, true)
      }
    },
    navigateToPage(pageNo, append = true, skipLoading = false) {
      // if paging enabled then clear items
      this.error = null
      // this.selectedItemIds = []
      if (this.paging || append === false) {
        this.items = []
        // this.selectedItemIds = []
        if (this.allowKeyboardNavigation) {
          this.currentCursorPosition = 0
        }
      }
      this.$emit('pageChange', pageNo)
      if (skipLoading === false) {
        this.startLoading()
      }
      this.$nextTick(() => {
        return this.fetchFn(
          this.activePageSize,
          this.getOffset(pageNo),
          this.sort
        )
          .then(({ items, total }) => {
            const allItems = UniqBy([...this.items, ...items], 'id').map((i) =>
              Object.freeze(PickBy(i, (v) => v !== undefined && v !== null))
            )
            /* handle current page with proper total items and offset -- for example
            if page size 10, total item 11, got to 11th item details page and archive it.
            After archive 11th item in list page will getting blank with current page 2
            so following code halde its current page like 1 */
            if (total && (allItems || []).length === 0 && pageNo !== 1) {
              return this.$nextTick(() => {
                this.navigateToPage(pageNo - 1)
              })
            }
            this.items = allItems
            // const chunks = Chunk(allItems, 10)
            // this.items = chunks[0]
            // if (this.activePageSize > 10) {
            //   chunks.forEach((chunk, index) => {
            //     if (index !== 0) {
            //       setTimeout(() => {
            //         requestAnimationFrame(() => {
            //           this.items = [...this.items, ...chunk]
            //         })
            //       }, 500)
            //     }
            //   })
            // }
            this.$emit('itemsReceived', allItems)
            this.total = total
            this.currentPage = pageNo
            this.stopLoading()
          })
          .catch((e) => {
            this.error = e
            this.stopLoading()
          })
      })
    },
    getOffset(pageNo) {
      return this.activePageSize * (pageNo - 1)
    },
    replaceAllItems(items) {
      this.items = items
    },
    pageSizeChange(size) {
      this.$nextTick(() => {
        this.activePageSize = size
        this.navigateToPage(1)
      })
    },
    // toggleExpand(item) {
    //   const index = FindIndex(this.items, { id: item.id })
    //   if (index !== -1) {
    //     this.items = [
    //       ...this.items.slice(0, index),
    //       { ...item, expanded: !item.expanded },
    //       ...this.items.slice(index + 1),
    //     ]
    //   }
    // },
  },
  render() {
    return this.$scopedSlots.default({
      items: this.items,
      sortedColumn: this.sort,
      selectedItemIds: this.selectedItemIds,
      selectedItemFullDetails: this.selectedItemFullDetails,
      currentPageSelectedItemIds: (this.selectedItemIds || []).filter(
        (s) => this.items.map(({ id }) => id).indexOf(s) >= 0
      ),
      pageInfo: {
        current: this.currentPage,
        pageSize: this.activePageSize,
        total: this.total,
      },
      loading: this.loading,
      loadingMore: this.loadingMore,
      navigateToPage: this.navigateToPage,
      changePageSize: this.pageSizeChange,
      nextPage: () =>
        this.hasMoreItem && this.navigateToPage(this.currentPage + 1),
      previousPage: () =>
        this.currentPage !== 1 && this.navigateToPage(this.currentPage - 1),
      reset: this.refresh,
      replaceItem: this.replace,
      appendItem: this.append,
      prependItem: this.prepend,
      removeItem: this.remove,
      loadMoreItems: this.loadMoreItems,
      shouldRenderPagination: this.paging
        ? this.hasMoreItem ||
          this.currentPage !== 1 ||
          this.total > this.activePageSize
        : this.hasMoreItem,
      paginationMode: this.paging ? 'paging' : 'loadmore',
      error: this.error,
      replaceAllItems: this.replaceAllItems,
      toggleSelectItem: this.toggleSelectItem,
      toggleSelectAll: this.toggleSelectAll,
      applySort: this.applySort,
      currentCursorPosition: this.currentCursorPosition,
      // toggleExpand: this.toggleExpand,
    })
  },
}
</script>
