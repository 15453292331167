<template>
  <v-popover
    ref="popperRef"
    :open="state"
    :disabled="disabled"
    :trigger="trigger"
    :placement="placement"
    popover-base-class="ant-popover"
    :popover-class="overlayClassName"
    popover-wrapper-class="ant-popover-content"
    popover-inner-class="ant-popover-inner"
    :popper-options="popperOptions"
    :boundaries-element="boundaryElement"
    :handle-resize="false"
    @show="handleShow"
    @hide="handleHide"
    @apply-hide="$emit('hide')"
    @apply-show="handleApplyShow"
  >
    <div class="">
      <slot name="trigger" />
    </div>
    <template v-slot:popover>
      <div
        v-if="isVisible"
        ref="popoverHolder"
        class="ant-popover-inner-content"
        :style="overlayStyle"
        :class="overlayClassName"
      >
        <slot />
      </div>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'MPopper',
  props: {
    open: { type: Boolean, default: false },
    controlled: { type: Boolean, default: false },
    trigger: {
      type: String,
      default: 'click',
    },
    placement: {
      type: String,
      default: 'auto',
    },
    getPopupContainer: {
      type: Function,
      default: undefined,
    },
    overlayClassName: {
      type: [String, Object, Array],
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    overlayStyle: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    this.$_resizeObserver = undefined
    return {
      isVisible: false,
    }
  },
  computed: {
    boundaryElement() {
      if (this.getPopupContainer) {
        return this.getPopupContainer()
      }
      return 'viewport'
    },
    state() {
      if (this.controlled) {
        return this.open
      }
      return this.isVisible
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
          },
          shift: {
            enabled: true,
          },
        },
      }
    },
  },
  methods: {
    handleApplyShow() {
      this.$emit('show')
      // debugger
      // const id = `popover_${this.$refs.popperRef.id}`

      // const ele = document.getElementById(id)

      // if (ele && this.overlayStyle.width) {
      //   ele.style.width = this.overlayStyle.width
      // }
    },
    handleHide() {
      if (this.$_resizeObserver) {
        this.$_resizeObserver.disconnect()
        this.$_resizeObserver = undefined
      }
      this.isVisible = false
    },
    handleShow() {
      this.isVisible = true
      this.$nextTick(() => {
        this.$_resizeObserver = new ResizeObserver(() => {
          if (this.$refs.popperRef) {
            this.$refs.popperRef.popperInstance.scheduleUpdate()
          }
        })

        this.$_resizeObserver.observe(this.$refs.popoverHolder)
      })
    },
  },
}
</script>

<style lang="less">
// .v-popover {
//   @apply flex flex-1 min-w-0;

//   .trigger {
//     display: flex !important;

//     @apply flex-1 min-w-0;

//     &:focus {
//       outline: none;
//       * {
//         outline: none;
//       }
//     }
//   }

//   &.action-btn-handle {
//     display: initial;
//     .trigger {
//       display: inline-block !important;
//     }
//   }
// }
// .@{ant-prefix}-popover,
// .v-popover,
// .@{ant-prefix}-tooltip {
//   &:focus {
//     outline: none;
//   }
// }
</style>
