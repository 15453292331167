<template>
  <FlotoCrudContainer
    ref="list"
    as-table
    :scrollable="scrollable"
    :module-name="parentModuleName"
    :fetch-fn="fetchTicketFn"
    :delete-fn="deleteComputerFn"
    :columns="tableColumns"
    :selectable="selectable"
    v-bind="$attrs"
    @selection-change="$emit('selection-change', $event)"
  >
    <template v-if="searchable" v-slot:add-controls="{ refreshList, items }">
      <MRow class="my-4">
        <MCol :size="8">
          <FlotoSearchBar
            ref="searchBar"
            :allow-keywords="false"
            :allow-save-search="false"
            :persist-criteria="false"
            :module-name="parentModuleName"
            :exclude-search-params="handleExcludeSearchParams"
            :addition-search-params="handleAdditionSearchParams"
            @change="applyCriteria($event, refreshList)"
          />
        </MCol>
        <MCol
          v-if="allowManualPatchAction && mode === 'missing'"
          :size="4"
          class="flex justify-end"
        >
          <slot name="add-controls" :items="items"></slot>
        </MCol>
      </MRow>
    </template>
    <template v-if="allowBulkAction" v-slot:bulk-actions="slotData">
      <BulkActions
        v-bind="{ ...$attrs, ...slotData }"
        :module-name="parentModuleName"
        :mode="mode"
        :allow-manual-patch-action="allowManualPatchAction"
        @delete="handleDelete"
      />
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:name="{ item }">
      <td class="text-ellipsis">
        <ResourceListTitle
          :name="item.name"
          open-in-new-tab
          :link="
            $modules.getModuleRoute('computer', 'view', {
              params: { id: item.id, configType: moduleName },
            })
          "
          :internal-link="!disableRoute"
          v-bind="$attrs"
        >
          <template v-slot:before-title>
            <FlotoDot
              :bg="item.active ? '#6bda7b' : '#ffe000'"
              class="cursor-pointer mr-2"
              :size="15"
            />
          </template>
        </ResourceListTitle>
      </td>
    </template>
    <template v-slot:hostName="{ item }">
      <td class="text-ellipsis">
        {{ item.hostName || '---' }}
      </td>
    </template>
    <template v-slot:ipAddress="{ item }">
      <td class="text-ellipsis">
        {{ item.ipAddress || '---' }}
      </td>
    </template>
    <template v-slot:osName="{ item }">
      <td class="text-ellipsis">
        {{ item.osName || '---' }}
      </td>
    </template>
    <template v-slot:agentVersion="{ item }">
      <td class="text-ellipsis">
        {{ item.agentVersion || '---' }}
      </td>
    </template>
    <template v-slot:servicePack="{ item }">
      <td class="text-ellipsis">
        {{ item.servicePack || '---' }}
      </td>
    </template>
    <template v-slot:architecture="{ item }">
      <td class="text-ellipsis">
        <ArchitecturePicker
          :value="item.architecture"
          :as-input="false"
          disabled
          :placeholder="'---'"
        />
      </td>
    </template>
    <template v-slot:loggedOnUser="{ item }">
      <td class="text-ellipsis">
        <FlotoUserDrawer
          v-if="item.loggedOnUser"
          :user-id="item.loggedOnUser"
        />
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:patchEnabled="{ item }">
      <td class="text-ellipsis">
        {{ item.patchEnabled || '---' }}
      </td>
    </template>
    <template v-slot:healthType="{ item }">
      <td class="text-ellipsis">
        <SystemHealthPicker
          :value="item.healthType"
          :as-input="false"
          disabled
          :placeholder="'---'"
        />
      </td>
    </template>
    <template v-slot:remoteOfficeId="{ item }">
      <td class="text-ellipsis">
        <MTag variant="neutral-lighter" :closable="false">
          {{ getRemoteOfficeName(item.remoteOfficeId) }}
        </MTag>
      </td>
    </template>
    <template v-slot:actions="{ item, remove }">
      <td
        :class="{
          'sticky-action': actionSticked.indexOf(item.id) >= 0,
          'hover-action-container': hoverableActions,
        }"
      >
        <slot
          name="action"
          :item="item"
          :stick-action="() => stickActionFor(item)"
          :unstick-action="() => unStickActionFor(item)"
        >
          <FlotoDeleteBtn
            :message="
              $t('confirm_delete_item', {
                item: `${$tc('computer')}`,
              })
            "
            @confirm="remove"
          />
        </slot>
      </td>
    </template>
    <template v-slot="slotData">
      <slot v-bind="slotData"></slot>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { generateId } from '@utils/id'
import { computerExcludeSearchParams } from '@components/search/sources'
import { getOperatorMaps } from '@data/form'
import ResourceListTitle from '@components/resource/resource-list-title'
import CloneDeep from 'lodash/cloneDeep'
import ArchitecturePicker from '@components/data-picker/architecture-picker'
import SystemHealthPicker from '@components/data-picker/system-health-picker'
import Uniq from 'lodash/uniq'
import { searchComputerApi } from '@modules/computer/computer-api'
import BulkActions from '@modules/computer/components/bulk-actions'
import { getRemoteOfficesApi } from '@modules/patch-management/api/remote-office-api.js'
export default {
  name: 'FlotoComputerList',
  components: {
    ResourceListTitle,
    ArchitecturePicker,
    SystemHealthPicker,
    BulkActions,
  },
  props: {
    moduleName: {
      type: String,
      default() {
        return this.$constants.PATCH_COMPUTERS
      },
    },
    ids: {
      type: Array,
      default: undefined,
    },
    excludedIds: {
      type: Array,
      default: undefined,
    },
    fetchFn: { type: Function, default: undefined },
    hasActions: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    scrollable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    includePlatformQual: { type: Boolean, default: false },
    platform: { type: String, default: undefined },
    hiddenColumns: {
      type: Array,
      default() {
        return []
      },
    },
    excludeSearchParams: {
      type: Array,
      default() {
        return []
      },
    },
    hoverableActions: { type: Boolean, default: false },
    bulkDeleteFn: { type: Function, default: undefined },
    deleteFn: { type: Function, default: undefined },
    type: { type: String, default: undefined },
    allowBulkAction: { type: Boolean, default: false },
    mode: { type: String, default: undefined },
    allowManualPatchAction: { type: Boolean, default: false },
    disableRoute: { type: Boolean, default: false },
  },
  data() {
    this.parentModuleName = this.$constants.AGENT
    this.computerExcludeSearchParams = computerExcludeSearchParams
    this.tableColumns = [
      {
        name: `${this.$tc('agent')} ${this.$tc('id')}`,
        key: 'name',
        width: '150px',
      },
      { name: `${this.$tc('host_name')}`, key: 'hostName' },
      { name: `${this.$tc('ip_address')}`, key: 'ipAddress' },
      { name: `${this.$tc('os_name')}`, key: 'osName' },
      {
        name: `${this.$tc('version')}`,
        key: 'agentVersion',
      },
      ...(this.hiddenColumns.indexOf('servicePack') < 0
        ? [{ name: this.$tc('service_pack'), key: 'servicePack' }]
        : []),
      ...(this.hiddenColumns.indexOf('architecture') < 0
        ? [{ name: this.$tc('architecture'), key: 'architecture' }]
        : []),
      ...(this.hiddenColumns.indexOf('loggedOnUser') < 0
        ? [{ name: this.$tc('used_by'), key: 'loggedOnUser' }]
        : []),
      ...(this.hiddenColumns.indexOf('patchEnabled') < 0
        ? [
            {
              name: `${this.$tc('patch')} ${this.$tc('status')}`,
              key: 'patchEnabled',
            },
          ]
        : []),
      ...(this.hiddenColumns.indexOf('healthType') < 0
        ? [
            {
              name: `${this.$tc('system_health')}`,
              key: 'healthType',
            },
          ]
        : []),
      ...(this.hiddenColumns.indexOf('remoteOfficeId') < 0
        ? [
            {
              name: `${this.$tc('remote_office')}`,
              key: 'remoteOfficeId',
            },
          ]
        : []),
      ...(this.hasActions
        ? [
            {
              name: `${this.$tc('action', 2)}`,
              key: 'actions',
              width: '120px',
            },
          ]
        : []),
    ]
    return {
      actionSticked: [],
      currentSelectedSearch: null,
      searchCriterias: {
        quals: [],
      },
      remoteOfficeList: [],
    }
  },
  computed: {
    handleAdditionSearchParams() {
      if (['missing', 'installed', 'ignored'].indexOf(this.mode) >= 0) {
        return [
          {
            key: generateId(),
            name: this.$tc('agent_id'),
            paramName: 'agentName',
            inputType: 'multiStringValue',
            valueType: 'string',
            exclusive: true,
            operators: [...getOperatorMaps().listCaseInsensitive],
            allowedModules: [this.$constants.AGENT],
          },
          {
            key: generateId(),
            name: this.$tc('tag', 2),
            paramName: 'agentTags',
            inputType: 'tags',
            valueType: 'string',
            exclusive: true,
            operators: [
              ...getOperatorMaps().tag,
              ...getOperatorMaps().empty_not_empty,
            ],
            allowedModules: [this.$constants.AGENT],
          },
        ]
      }
      return []
    },
    handleExcludeSearchParams() {
      if (this.excludeSearchParams.length) {
        switch (this.moduleName) {
          case this.$constants.PACKAGES_REMOTE_DEPLOYMENT ||
            this.$constants.REGISTRY_REMOTE_DEPLOYMENT:
            return [
              ...this.excludeSearchParams,
              'healthType',
              'os_platform',
              'lastRefreshCallTime',
            ]
          case this.$constants.ASSET_CONFIGURATION:
            return [...this.excludeSearchParams, 'healthType']
          default:
            return this.excludeSearchParams
        }
      } else {
        if (['missing', 'installed', 'ignored'].indexOf(this.mode) >= 0) {
          return [...this.computerExcludeSearchParams, 'name', 'tags']
        }
        return this.computerExcludeSearchParams
      }
    },
  },
  methods: {
    handleDelete(ids) {
      this.$emit('delete', ids)
    },
    deleteComputerFn(item) {
      if (this.deleteFn) {
        return this.deleteFn(item)
      } else {
        return Promise.resolve({})
      }
    },
    applyCriteria(searchCriteria, refreshList) {
      this.searchCriterias = searchCriteria
      refreshList()
    },
    fetchTicketFn(limit, offset) {
      const criterias = CloneDeep(this.searchCriterias)
      let filters = {}
      if (this.excludedIds) {
        filters.excludedIds = this.excludedIds
      }
      if (this.ids) {
        filters.ids = this.ids
      }
      if (this.includePlatformQual) {
        if (this.moduleName !== this.$constants.PACKAGES_COMPUTERS) {
          filters.platform = 'windows'
        }
      }
      if (this.platform) {
        filters.autoDeploymentPlatform = this.platform
      }
      if (this.fetchFn) {
        return this.fetchFn(filters, criterias, limit, offset).then((data) => {
          const ids = data.items.map((i) => i.remoteOfficeId)
          if (ids.length) {
            this.getRemoteOffice(ids)
          }
          return data
        })
      } else {
        return searchComputerApi(filters, criterias, limit, offset).then(
          (data) => {
            const ids = data.items.map((i) => i.remoteOfficeId)
            if (ids.length) {
              this.getRemoteOffice(ids)
            }
            return data
          }
        )
      }
    },
    refresh() {
      this.$refs.list.refresh()
    },
    stickActionFor(item) {
      this.actionSticked = Uniq([...this.actionSticked, item.id])
    },
    unStickActionFor(item) {
      this.actionSticked = this.actionSticked.filter((id) => id !== item.id)
    },
    getRemoteOffice(ids) {
      return getRemoteOfficesApi({ ids }, undefined, undefined, {
        archived: true,
      }).then((data) => {
        this.remoteOfficeList = data.items
      })
    },
    getRemoteOfficeName(id) {
      const data = this.remoteOfficeList.find((i) => i.id === id)
      return (data || {}).name
    },
  },
}
</script>
