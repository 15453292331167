<template>
  <span>
    <MRadioGroup
      v-if="!asDropdown"
      v-model="currentModule"
      as-button
      :options="moduleOptions"
      :disabled="disabled"
      @change="confirmChangeModule"
    />
    <FlotoDropdownPicker
      v-else
      v-model="currentModule"
      as-input
      searchable
      :options="moduleOptions"
      :allow-clear="false"
      :disabled="disabled"
      v-bind="$attrs"
      @change="confirmChangeModule"
    >
      <template v-slot:trigger="d">
        <slot name="trigger" :selected-item="selectedModule" v-bind="d" />
      </template>
    </FlotoDropdownPicker>
    <FlotoConfirmModal
      :open="showConfirmModuleChangeModal"
      no-icon-shadow
      @hide="cancelChangeModule"
      @confirm="changeModule"
    >
      <template v-slot:icon>
        <MIcon
          name="exclamation-circle"
          class="w-full text-secondary-orange"
          size="3x"
        />
      </template>
      <template v-slot:message>
        {{ $t('confirm_reset_form') }}
      </template>
    </FlotoConfirmModal>
  </span>
</template>

<script>
import { LicenseComputed } from '@state/modules/license'
export default {
  name: 'FlotoModuleSelector',
  model: {
    event: 'change',
  },
  props: {
    value: { type: [String, Array], required: true },
    confirm: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    asDropdown: { type: Boolean, default: false },
    showAllOption: { type: Boolean, default: false },
    availableModules: { type: Array, default: undefined },
    options: { type: Array, default: undefined },
    additionalModules: { type: Array, default: undefined },
    showAssetUpdateModule: { type: Boolean, default: false },
  },
  data() {
    return {
      currentModule: undefined,
      showConfirmModuleChangeModal: false,
      moduleOptions: [],
    }
  },
  computed: {
    ...LicenseComputed,
    selectedModule() {
      return this.moduleOptions.find((x) => x.key === this.value)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currentModule = newValue
      },
    },
  },
  created() {
    if (this.options) {
      return (this.moduleOptions = this.options)
    }
    this.moduleOptions = [
      ...(this.showAllOption
        ? [{ text: this.$tc('all'), key: 'all', value: 'all' }]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.REQUEST) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.REQUEST) >= 0
        ? [
            {
              text: this.$tc('request'),
              value: this.$constants.REQUEST,
              key: this.$constants.REQUEST,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.SERVICE_CATALOG) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.SERVICE_CATALOG) >=
        0
        ? [
            {
              text: this.$tc('service_catalog'),
              value: this.$constants.SERVICE_CATALOG,
              key: this.$constants.SERVICE_CATALOG,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.PROBLEM) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.PROBLEM) >= 0
        ? [
            {
              text: this.$tc('problem'),
              value: this.$constants.PROBLEM,
              key: this.$constants.PROBLEM,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.CHANGE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.CHANGE) >= 0
        ? [
            {
              text: this.$tc('change'),
              value: this.$constants.CHANGE,
              key: this.$constants.CHANGE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.RELEASE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.RELEASE) >= 0
        ? [
            {
              text: this.$tc('release'),
              value: this.$constants.RELEASE,
              key: this.$constants.RELEASE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.ASSET) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: this.$tc('asset'),
              value: this.$constants.ASSET,
              key: this.$constants.ASSET,
            },
            ...(this.showAssetUpdateModule
              ? [
                  {
                    text: this.$tc('asset_update'),
                    value: this.$constants.ASSET_UPDATE,
                    key: this.$constants.ASSET_UPDATE,
                  },
                ]
              : []),
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.ASSET_HARDWARE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: this.$tc('asset_hardware'),
              value: this.$constants.ASSET_HARDWARE,
              key: this.$constants.ASSET_HARDWARE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.ASSET_SOFTWARE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: this.$tc('asset_software'),
              value: this.$constants.ASSET_SOFTWARE,
              key: this.$constants.ASSET_SOFTWARE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.ASSET_NON_IT) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: this.$tc('asset_non_it'),
              value: this.$constants.ASSET_NON_IT,
              key: this.$constants.ASSET_NON_IT,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.ASSET_CONSUMABLE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: this.$tc('asset_consumable'),
              value: this.$constants.ASSET_CONSUMABLE,
              key: this.$constants.ASSET_CONSUMABLE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.ASSET_MOVEMENT) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: `${this.$tc('asset')} ${this.$tc('movement')}`,
              value: this.$constants.ASSET_MOVEMENT,
              key: this.$constants.ASSET_MOVEMENT,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.CONTRACT) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.CONTRACT) >= 0
        ? [
            {
              text: this.$tc('contract'),
              value: this.$constants.CONTRACT,
              key: this.$constants.CONTRACT,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.PURCHASE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.PURCHASE) >= 0
        ? [
            {
              text: this.$tc('purchase'),
              value: this.$constants.PURCHASE,
              key: this.$constants.PURCHASE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.PATCH) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.PATCH) >= 0
        ? [
            {
              text: this.$tc('patch'),
              value: this.$constants.PATCH,
              key: this.$constants.PATCH,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.DEPLOYMENT) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.DEPLOYMENT) >= 0
        ? [
            {
              text: this.$tc('deployment'),
              value: this.$constants.REMOTE_DEPLOYMENT,
              key: this.$constants.REMOTE_DEPLOYMENT,
            },
          ]
        : []),
      ...(!this.availableModules ||
      this.availableModules.indexOf(this.$constants.KNOWLEDGE) >= 0
        ? [
            {
              text: this.$tc('knowledge'),
              value: this.$constants.KNOWLEDGE,
              key: this.$constants.KNOWLEDGE,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.TASK) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.TASK) >= 0
        ? [
            {
              text: this.$tc('task'),
              value: this.$constants.TASK,
              key: this.$constants.TASK,
            },
          ]
        : []),
      ...((!this.availableModules ||
        this.availableModules.indexOf(this.$constants.SOFTWARE_LICENSE) >= 0) &&
      this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
        ? [
            {
              text: this.$tc('software_license'),
              value: this.$constants.SOFTWARE_LICENSE,
              key: this.$constants.SOFTWARE_LICENSE,
            },
          ]
        : []),
      ...(!this.availableModules ||
      this.availableModules.indexOf(this.$constants.USER) >= 0
        ? [
            {
              text: this.$tc('user'),
              value: this.$constants.USER,
              key: this.$constants.USER,
            },
          ]
        : []),
      ...(this.availableModules.indexOf(this.$constants.USER_LEAVE) >= 0
        ? [
            {
              text: this.$tc('user_leave'),
              value: this.$constants.USER_LEAVE,
              key: this.$constants.USER_LEAVE,
            },
          ]
        : []),
      ...(!this.availableModules ||
      (this.availableModules.indexOf(this.$constants.PROJECT) >= 0 &&
        this.availableModulesInLicense.indexOf(this.$constants.PROJECT) >= 0)
        ? [
            {
              text: this.$tc('project'),
              value: this.$constants.PROJECT,
              key: this.$constants.PROJECT,
            },
          ]
        : []),
      ...(!this.availableModules ||
      this.availableModules.indexOf(this.$constants.ADMIN) >= 0
        ? [
            {
              text: this.$tc('admin'),
              value: this.$constants.ADMIN,
              key: this.$constants.ADMIN,
            },
          ]
        : []),
      ...(this.additionalModules && (this.additionalModules || []).length > 0
        ? this.additionalModules
        : []),
    ]
  },
  methods: {
    confirmChangeModule(newModule) {
      if (this.confirm) {
        this.showConfirmModuleChangeModal = true
      } else {
        this.changeModule(newModule)
      }
    },
    cancelChangeModule() {
      this.currentModule = this.value
      this.showConfirmModuleChangeModal = false
    },
    changeModule(newModule) {
      this.$emit('change', newModule || this.currentModule)
      this.showConfirmModuleChangeModal = false
    },
  },
}
</script>
